import React, { useState } from "react"
import { ArrowRight } from "react-feather"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { Link } from "gatsby"
import { AffiliateList } from "../components/AffiliateList"
import clinicDoor from "../assets/images/clinic-door.webp"
import tfl from "../assets/icons/tfl.svg"
import cadoganSmallSquare from "../assets/images/cadogan-small-square.jpg"
import call from "../assets/icons/call-white.svg"
import location from "../assets/icons/locationOnWhite.svg"
import locationDark from "../assets/icons/location_on_black.svg"
import mail from "../assets/icons/mailWhite.svg"
import clinicDoorX2 from "../assets/images/clinic-door-x2.webp"
import containerClasses from "../styles/container.module.css"
import buttons from "../styles/buttons.module.css"
import londonlauriston from "../assets/images/London-Lauriston.jpg"

const Contact = ({ data }) => {
  const [termsState, setTermsState] = useState(false)

  return (
    <Layout page="Contact">
      <SEO
        title="Contact Miss Hawkes | Oculoplastic Surgeon & Aesthetic Practitioner"
        description="Are you looking to book a consultation, or do you need to contact Miss Hawkes? Get in touch today, and a team member will reply within one working day."
      />
      <div className="pt-16 text-center bg-white md:pt-20">
        <div className={`${containerClasses.container}`}>
          <div className="col-span-4 md:col-span-6 md:col-start-2 lg:col-span-6 lg:col-start-4 xl:col-span-4 xl:col-start-5">
            {/* <p className="mb-0 overline">Contact</p> */}
            <h1 className="mt-0 text-blackNew fluid-display-01">Contact Us</h1>
            <p className="mt-0 mb-2 text-base leading-tight md:mb-6 lg:mb-8 xl:mb-10 text-blackNew lg:col-span-6 lg:col-start-4">
              For appointments or enquiries, please contact us. A member of the
              team will reply within one working day.
            </p>
          </div>
          <figure className="col-span-4 md:col-span-8 lg:col-span-10 lg:col-start-2">
            <div className="transform translate-y-1/2">
              <img
                className="w-full -mt-16 md:-mt-40 lg:-mt-48 xl:-mt-64"
                loading="lazy"
                alt="Cadogan Clinic London on Sloane Street Main Entrance"
                src={clinicDoor}
                srcSet={`${clinicDoor} 1x, ${clinicDoorX2} 2x`}
              />
            </div>
          </figure>
        </div>
      </div>
      <div className="pt-40 pb-12 bg-white2 md:pt-25p lg:pt-64 md:pb-16 lg:pb-20">
        <div className={`${containerClasses.container}`}>
          <div className="col-span-4 md:col-span-8 lg:col-span-6">
            <h2 className="mt-0 fluid-heading-04 xl:pt-8">Contact Details</h2>
            <div className="flex mt-4">
              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mr-4 bg-royalBlue lg:mr-4 lg:w-12 lg:h-12">
                <img loading="lazy" className="h-5" src={mail} alt="Envelope" />
              </div>
              <div>
                <h3 className="mt-0 mb-0 font-mono text-sm tracking-widest uppercase text-gold600">
                  Email
                </h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`mailto:${data?.site?.siteMetadata?.email}`}
                  className="mt-1 font-serif text-xl lg:mt-2 lg:text-xl"
                >
                  {data?.site?.siteMetadata?.email}
                </a>
              </div>
            </div>
            <div className="flex mt-6 lg:mt-8">
              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mr-4 lg:mr-4 lg:w-12 lg:h-12 bg-royalBlue">
                <img loading="lazy" className="h-5" src={call} alt="Phone" />
              </div>
              <div>
                <h3 className="mt-0 mb-0 font-mono text-sm tracking-widest uppercase text-gold600">
                  Phone Number
                </h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`tel:${data?.site?.siteMetadata?.telephone}`}
                  className="mt-1 font-serif text-xl lg:text-xl"
                >
                  {data?.site?.siteMetadata?.telephone}
                </a>
              </div>
            </div>
            <div className="flex mt-6 lg:mt-8">
              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mr-4 lg:mr-4 lg:w-12 lg:h-12 bg-royalBlue">
                <img
                  loading="lazy"
                  className="h-5"
                  src={location}
                  alt="Location Pin"
                />
              </div>
              <div>
                <h3 className="mt-0 mb-0 font-mono text-sm tracking-widest uppercase text-gold600">
                  Clinic Addresses
                </h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/place/120+Sloane+St,+Chelsea,+London+SW1X+9BW/@51.4944335,-0.1607926,17z/data=!3m1!4b1!4m5!3m4!1s0x4876053e0792d337:0xc4910b4996eba637!8m2!3d51.4944302!4d-0.1585986/"
                  className="block mt-1 font-serif text-xl lg:text-xl"
                >
                  {data?.site?.siteMetadata?.address}
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://maps.app.goo.gl/jLX3e61TgXN5iSUC7"
                  className="block mt-1 font-serif text-xl lg:text-xl"
                >
                  {data?.site?.siteMetadata?.address_2}
                </a>
              </div>
            </div>
          </div>
          <div className="col-span-4 mt-8 lg:row-span-1 md:col-span-8 lg:col-span-6 lg:col-start-7 lg:mt-0 xl:pt-8">
            <h2 className="mt-0 fluid-heading-04">Contact Form</h2>
            <form
              name="contact"
              action="/success"
              className="mt-8"
              method="post"
              r
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="bot-field" />
              <div>
                <label
                  className="font-mono text-sm tracking-wider uppercase"
                  htmlFor="first-name"
                >
                  Full Name
                </label>
                <input
                  className="block w-full h-10 bg-transparent border-black border-solid border-b-1"
                  id="first-name"
                  name="firstName"
                  type="text"
                  required
                />
              </div>
              <div className="mt-6">
                <label
                  className="font-mono text-sm tracking-wider uppercase"
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  className="block w-full h-10 bg-transparent border-black border-solid border-b-1"
                  id="email"
                  name="email"
                  type="email"
                  required
                />
              </div>
              <div className="mt-6">
                <label
                  className="font-mono text-sm tracking-wider uppercase"
                  htmlFor="email"
                >
                  Phone Number
                </label>
                <input
                  className="block w-full h-10 bg-transparent border-black border-solid border-b-1"
                  id="telephone"
                  name="telephone"
                  type="tel"
                />
              </div>
              <div className="mt-6">
                <label
                  className="font-mono text-sm tracking-wider uppercase"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  className="block w-full h-20 bg-transparent border-black border-solid border-b-1"
                  id="message"
                  name="message"
                />
              </div>
              <button
                id="get-in-touch-submit"
                className={`${buttons.btnLargeExpressive} mt-4 w-full md:w-auto lg:w-fit mb-2`}
                type="submit"
                disabled={!termsState}
              >
                <div className="flex items-center justify-between">
                  Submit
                  <ArrowRight className="ml-10 -mr-3" size="16" />
                </div>
              </button>
            </form>
            <input
              type="checkbox"
              id="agreeterms"
              name="agreeterms1"
              value="consent"
              onChange={e => setTermsState(e.target.checked)}
              className="pt-4 mr-2"
            ></input>
            <label htmlFor="agreeterms1" className="text-sm">
              I agree to the{""}
              <Link
                className="ml-1 text-sm underline text-gold600"
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
              {""} and {""}
              <Link
                className="text-sm underline text-gold600"
                to="/terms-and-conditions"
              >
                Terms &amp; Conditions
              </Link>
            </label>
            <br></br>
          </div>
        </div>
      </div>
      <div className="py-12 bg-white md:py-16 lg:py-20">
        <div className={`${containerClasses.container}`}>
          <div className="col-span-4 md:col-span-8 lg:col-span-6">
            <h2 className="mt-0 fluid-heading-05">Booking Information</h2>
          </div>

          <div className="col-span-4 md:col-span-8 lg:col-span-6">
            <h3 className="mt-0 lg:pt-6 fluid-heading-03">Cadogan Clinic</h3>
            <p className="mt-1 mb-0">
              For patients who wish to have their appointment at the Cadogan
              Clinic on Sloane Street, Chelsea, please use the email address,
              phone number, or contact form above.
            </p>
          </div>
          <div className="col-span-4 mt-0 lg:row-span-1 md:col-span-8 lg:col-span-6 lg:col-start-7 lg:mt-0 xl:pt-4">
            <h3 className="mt-0 fluid-heading-03">London Lauriston Clinic</h3>
            <p className="mt-1 mb-0">
              Patients wanting to have their appointment at the London Lauriston
              Clinic (Great Titchfield St, near Oxford Circus) can contact us in
              the same way or by{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://partner.pabau.com/online-bookings/kensington-ophthalmology?category=116789&serviceType=0"
              >
                online booking
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <div className="pt-12 pb-12 bg-white2 md:py-16 lg:pt-20 lg:pb-10">
        <div className={`${containerClasses.container}`}>
          {/* First column */}
          <div className="col-span-4 md:col-span-8 lg:col-span-6">
            <img
              src={cadoganSmallSquare}
              alt="Cadogan Clinic London on Sloane Street Main Entrance"
              className="h-20 mr-2 rounded-full md:h-24 lg:h-32"
            />
            <h2 className="mt-6 mb-5 text-5xl font-bold md:mb-6 md:text-7xl lg:text-8xl">
              Cadogan Clinic
            </h2>
            <div className="mt-4 space-y-4">
              <div className="flex items-center gap-2">
                <img
                  loading="lazy"
                  className="h-6"
                  src={locationDark}
                  alt="Location Pin"
                />
                <p className="mb-0">120 Sloane St, Chelsea, London SW1X 9BW</p>
              </div>

              <div className="flex items-center gap-2">
                <img
                  src={tfl}
                  className="object-cover h-4 mr-1 rounded-xl"
                  alt="TFL icon"
                />
                <p className="mb-0">
                  <span className="font-medium text-gray-900">
                    Nearest station:
                  </span>{" "}
                  Sloane Square, 5 min walk
                </p>
              </div>

              <button
                className={`${buttons.btnLargeExpressive} mt-4 w-full md:w-auto lg:w-fit mb-2`}
                onClick={() =>
                  window.open(
                    "https://www.google.com/maps/dir//Cadogan+Clinic,+Sloane+Street,+London/@51.4945333,-0.3229104,11z/data=!3m1!5s0x4876053fa2137d31:0x8e65632e3e5cf89d!4m8!4m7!1m0!1m5!1m1!1s0x4876053e07a81cdd:0x216ae7f8e68b92e3!2m2!1d-0.1584425!2d51.4944345?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                  )
                }
              >
                Plan my journey
              </button>
            </div>
          </div>
          {/* Second column */}
          <div className="col-span-4 p-4 md:col-span-8 lg:col-span-6 lg:col-start-7">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.0225010792533!2d-0.16108842241676286!3d51.4944545718103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876053e0792d337%3A0xc4910b4996eba637!2s120%20Sloane%20St%2C%20London%20SW1X%209BW!5e0!3m2!1sen!2suk!4v1734472194447!5m2!1sen!2suk"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Google Map"
            />
          </div>
        </div>
      </div>
      <div className="py-12 border-gray-200 border-solid bg-white2 lg:pt-10 lg:pb-20 md:py-16 border-t-1">
        <div className={`${containerClasses.container}`}>
          {/* First column */}
          <div className="col-span-4 md:col-span-8 lg:col-span-6">
            <img
              src={londonlauriston}
              alt="London Lauriston Clinic Main Entrance"
              className="h-20 mr-2 rounded-full md:h-24 lg:h-32"
            />
            <h2 className="mt-6 mb-5 text-5xl font-bold md:mb-6 md:text-7xl lg:text-8xl">
              London Lauriston Clinic
            </h2>
            <div className="mt-4 space-y-4">
              <div className="flex items-center gap-2">
                <img
                  loading="lazy"
                  className="h-6"
                  src={locationDark}
                  alt="Location Pin"
                />
                <p className="mt-2 mb-0">
                  34 Great Titchfield St, London W1W 8BQ{" "}
                </p>
              </div>

              <div className="flex items-center gap-2">
                <img
                  src={tfl}
                  className="object-cover h-4 mr-1 rounded-xl"
                  alt="TFL icon"
                />
                <p className="mb-0">
                  <span className="font-medium text-gray-900">
                    Nearest station:
                  </span>{" "}
                  Oxford Circus, 5 min walk
                </p>
              </div>

              <button
                className={`${buttons.btnLargeExpressive} mt-4 w-full md:w-auto lg:w-fit mb-2`}
                onClick={() =>
                  window.open(
                    "https://www.google.com/maps/dir//London+Lauriston+Clinic,+34+Great+Titchfield+St.,+London+W1W+8BQ/@51.517553,-0.1400165,12z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x48761bcd8c002873:0xb439edc67c40ce54!2m2!1d-0.1400165!2d51.517553!3e2?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                  )
                }
              >
                Plan my journey
              </button>
            </div>
          </div>
          {/* Second column */}
          <div className="col-span-4 p-4 md:col-span-8 lg:col-span-6 lg:col-start-7">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.7635961109117!2d-0.1425914224158757!3d51.51755297181577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761bcd8c002873%3A0xb439edc67c40ce54!2sLondon%20Lauriston%20Clinic!5e0!3m2!1sen!2suk!4v1734471997326!5m2!1sen!2suk"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Google Map"
            />
          </div>
        </div>
      </div>
      <AffiliateList />
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query {
    site {
      siteMetadata {
        telephone
        email
        address
        address_2
      }
    }
  }
`
